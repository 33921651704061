<template>
  <div>
    <!-- begin::Entry -->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid py-8">
        <!--begin::Education-->
        <div class="d-flex flex-row">
          <UserNav activePage="videotest" activeTab="4"></UserNav>
          <!--begin::Content-->
          <div class="flex-row-fluid">

            <div class="d-flex flex-column-fluid">
              <!--begin::Container-->
              <div class="container-fluid">
                <div>
                  <!--begin::Section-->
                  <div>
                    <loading :active.sync="visible" :can-cancel="true">
                      <div class="loader__container">
                        <img class="w100 h100" src="/media/logos/ingomu-preloader.gif" />
                      </div>
                    </loading>
                    <div class="row">
                      <div class="col-lg-5">
                        <!--begin::Card-->
                        <div class="card card-custom card-stretch gutter-b">
                          <!--begin::Body-->
                          <div class="card-body pt-4">
                            <!--begin::User-->
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                <img class="w100 h100" :src="photo" />
                              </div>
                              <div>
                                <a href="#" class="font-weight-bold font-size-h5 text-dark text-hover-primary">{{
                                  current_name
                                }}</a>
                                <div class="text-dark">{{ current_title }}</div>
                                <div class="mt-2">
                                  <button class="btn btn-xs font-weight-bold mr-2 my-1" :class="{
                                    'btn btn-danger': !checked,
                                    'btn btn-success': checked,
                                  }">
                                    <b-form-checkbox v-model="checked" name="check-button" switch size="sm"
                                      :disabled="checkedDisabled">
                                    </b-form-checkbox>
                                  </button>
                                  <button class="btn btn-xs" :class="{
                                    'btn btn-danger': !livestreamActive,
                                    'btn btn-success': livestreamActive,
                                  }">
                                    {{ statusText }}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <!--end::User-->
                            <!--begin::Contact-->
                            <div class="pt-8 pb-6">
                              <div v-show="ingestServerCred != '' && streamKeyCred != ''">
                                <div class="text-right">
                                  <button class="btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bold"
                                    @click="copyText('ingest')">Copy Live
                                    Ingest Server</button>
                                  <div class="font-weight-bold mb-2 overflow-hidden">
                                    <input class="font-weight-bold text-dark width420 text-right mt-2" readonly
                                      type="text" v-model="ingestServerCred" />
                                    <input type="hidden" id="ingestInput" :value="ingestServerCred" />
                                  </div>
                                </div>
                                <div class="mt-5 text-right">
                                  <button class="btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bold"
                                    @click="copyText('stream')">Copy Live
                                    Stream Key</button>
                                  <div class="font-weight-bold mb-2 overflow-hidden">
                                    <input type="hidden" id="streamInput" :value="streamKeyCred" />
                                    <input class="font-weight-bold text-dark width420 text-right mt-2" readonly
                                      v-model="streamKeyCred" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--end::Contact-->
                            <!--begin::Contact-->
                            <div class="pb-6">To generate your Live Ingest
                              Server and Live Stream Key you must
                              first toggle on the session by clicking the red toggle switch
                              to the right, then you can copy and paste the keys in Streamlabs OBS.<br /><br />
                              Some browser extensions may cause issues. We recommend that
                              browser extensions are turned off to use this service. For more
                              information check out the
                              <a href="https://www.alphr.com/how-to-disable-extensions-in-chrome/"
                                class="font-weight-bold" target="_blank">documentation</a>.
                            </div>
                            <!--end::Contact-->
                          </div>
                          <!--end::Body-->
                          <div class="card-footer">
                            <a href="https://streamlabs.com/" target="_blank">
                              <button class="btn btn-ingomublue font-weight-bold py-3 px-6 mb-2 text-center btn-block">
                                <img src="media/client-logos/Streamlabs.png" class="w15 h15 mr-1" />
                                Streamlabs OBS
                              </button>
                            </a>
                          </div>
                        </div>
                        <!--end::Card-->
                      </div>
                      <div class="col-lg-7">
                        <div class="card card-stretch gutter-b mh-700">
                          <!--begin::Header-->
                          <div class="card-header border-0 pt-5 pb-4 mh-0">
                            <!-- <h3 class="card-title align-items-end flex-column">
                    <span class="card-label font-weight-bolder text-dark fs-15">
                      <h3 class="font-weight-bolder text-dark fs-15">Video Test</h3>
                    </span>
                  </h3> -->
                            <div class="card-toolbar">
                              <flip-countdown class="w350 float-center" :deadline="deadline" :showDays="false"
                                @timeElapsed="timeElapsedHandler" countdownSize="30px" labelSize="10px"></flip-countdown>
                            </div>
                          </div>
                          <!-- end::Header -->
                          <!--begin::Body-->
                          <div class="h-100">
                            <div id="videocontainer" class="col-md-12">
                              <div class="smartphone">
                                <div class="content w-100 h-100 p-0">
                                  <div v-show="isLivestreamActive == true">
                                    <video ref="videoPlayer" class="video-js"></video>
                                    <div class="widget--profile widget--profile-video">
                                      <img class="widget--profile__image_small" :src="photo" alt="">
                                      <div class="live-box">Live</div>
                                    </div>
                                  </div>
                                  <div v-show="isLivestreamActive == false"
                                    class="no-livestream w-100 h-100 overflow-hidden">
                                    <div class="no-livestream-img">
                                      <div class="text-center">
                                        <img class="widget--profile__image_medium" :src="photo" alt="">
                                        <br /><br />
                                        <p class="offline-text">Livestream is offline</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Section-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { registerIVSTech } from "amazon-ivs-player";
import { mapGetters } from "vuex";
import FlipCountdown from "vue2-flip-countdown";
import moment from "moment";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import UserNav from "@/view/layout/header/UserNav.vue";
const fmt = "YYYY-MM-DD HH:mm:ss";

export default {
  components: {
    FlipCountdown,
    Loading: VueLoading,
    UserNav
  },
  data() {
    return {
      visible: false,
      checked: false,
      checkedText: "Off",
      checkedDisabled: false,
      statusText: "Offline",
      showRefresh: false,
      timeout: null,
      deadline: moment().format(fmt),
      tabIndex: 0,
      ingestServerCred: "",
      streamKeyCred: "",
      showButtonText: "Show",
      player: null,
      videoSource: "",
      videoOptions: {
        autoplay: true,
        muted: false,
        controls: false,
        techOrder: ["AmazonIVS"],
        height: "300",
        aspectRatio: "16:9",
        responsive: true,
        fluid: true,
      },
      default_photo: "media/users/blank.png",
      current_photo: null,
      current_name: "",
      current_title: "",
      isLivestreamActive: false,
    };
  },
  created() {
    window.addEventListener("beforeunload", function (e) {
      // the absence of a returnValue property on the event will guarantee the browser unload happens
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = "Did you switch off the recording?";
    });
  },
  beforeDestroy() {
    // when navigating to other components
    this.$store.dispatch("gcfDeleteIVSChannel");
  },
  beforeUnmount() {
    // Destroy the player instance
    if (this.player) {
      this.player.dispose();
    }
  },
  mounted() {
    this.$store.dispatch("getCoachLivestreamInfo");
    this.$store.commit("CHANGE_TABINDEX", { tabIndex: 3 });
    this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 5 });
    registerIVSTech(videojs, {
      wasmWorker: this.createAbsolutePath(
        "/assets/amazon-ivs-wasmworker.min.js"
      ),
      wasmBinary: this.createAbsolutePath(
        "/assets/amazon-ivs-wasmworker.min.wasm"
      ),
    });

    this.player = videojs(this.$refs.videoPlayer, this.videoOptions);

    const maintanenceBlock = async () => {
      try {
        await this.$store.dispatch("deleteIVSChannel", { deleteAll: true });
        const listChannels = await this.$store.dispatch("listIVSChannels");
        // console.log(listChannels);
      } catch (e) {
        // console.log(e);
      }
    };
    // maintanenceBlock();
  },
  methods: {
    createAbsolutePath(assetPath) {
      return new URL(assetPath, document.URL).toString();
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    copyText(copyType) {
      let testingCodeToCopy;
      if (copyType === "ingest") {
        testingCodeToCopy = document.querySelector("#ingestInput");
      } else if (copyType === "stream") {
        testingCodeToCopy = document.querySelector("#streamInput");
      }
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        Swal.fire({
          title: "",
          text: "The code was copied!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: true,
        });
      } catch (err) {
        Swal.fire({
          title: "",
          text: "Unable to copy!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: true,
        });
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    timeElapsedHandler: function () {
      this.turnOff();
    },
    turnOff() {
      this.checkedText = "Off";
      this.checked = false;
      this.$store.dispatch("deleteIVSChannel");
      clearTimeout(this.timeout);
      this.showRefresh = false;
      this.deadline = moment().format(fmt);
      this.$store.commit("RESET_LIVESTREAMACTIVE");
    },
    noSlotsAvailable() {
      this.checkedText = "Off";
      this.checked = false;
      this.deadline = moment().format(fmt);
      Swal.fire({
        title: "",
        text: "There are no recording slots available right now!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: true,
      });
    },
  },
  watch: {
    courseInfo(newValue) {
      this.current_photo = newValue[0].coach.image;
      this.current_name = newValue[0].coach.first;
      this.current_title = newValue[0].title;
    },
    checked(value) {
      if (value) {
        this.visible = true;
        const runBlock = async () => {
          try {
            const channels = await this.$store.dispatch("listIVSChannels");
            if (channels < 200) {
              this.checkedText = "On";
              await this.$store.dispatch("createIVSChannel");
              this.$store.dispatch("getIVSChannelInfo");
            } else {
              this.noSlotsAvailable();
              this.visible = false;
            }
          } catch (e) {
            this.turnOff();
            this.visible = false;
          }
        };
        runBlock();
      } else {
        this.turnOff();
        this.checkedDisabled = true;
        this.visible = false;
        this.statusText = "Offline";
      }
    },
    ingestServer(newValue, oldValue) {
      this.ingestServerCred = newValue;
      this.visible = false;
    },
    streamKey(newValue, oldValue) {
      this.streamKeyCred = newValue;
      this.visible = false;
    },
    playbackUrl(newValue, oldValue) {
      if (newValue !== null) {
        this.videoSource = newValue;
      } else {
        this.player.reset();
      }
    },
    livestreamActive(newValue, oldValue) {
      if (newValue == "LIVE") {
        this.deadline = moment().add(15, "m").format(fmt);
        this.player.src({
          src: this.videoSource,
          type: "application/x-mpegURL"
        });
        this.statusText = "Online";
        this.isLivestreamActive = true
      } else {
        this.isLivestreamActive = false
      }
    },
    channelTimeout(newValue, oldValue) {
      if (newValue) {
        this.turnOff();
        this.checkedDisabled = true;
        this.visible = false;
        this.statusText = "Offline";
        this.isLivestreamActive = false
      }
    },
  },
  computed: {
    ...mapGetters([
      "ingestServer",
      "playbackUrl",
      "streamKey",
      "livestreamActive",
      "channelTimeout",
      "courseInfo"
    ]),
    checkboxTextColor: function () {
      return this.checkedDisabled ? "text-disabled" : "text-dark";
    },
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  beforeRouteLeave(to, from, next) {
    const answer = window.confirm('Do you really want to leave?')
    if (answer) {
      next()
    } else {
      next(false)
    }
  }
};
</script>

<style scoped>
.mh-700 {
  min-height: 700px;
}

.width420 {
  width: 420px;
}

/* hide default vue-bootstrap tab links */
.hide-tabs>div:not(.tab-content) {
  display: none;
}

#videocontainer {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  left: 0;
  right: 0;
  max-height: 400px;
}

.flip-card {
  font-size: 2rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.w350 {
  width: 350px;
}

.h-400 {
  height: 400px;
}

.video-js,
.vjs-tech {
  position: absolute !important;
  height: 100% !important;
  border-radius: none !important;
}

.widget--profile__image_small {
  margin-right: 15px;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.widget--profile__image_medium {
  margin-right: 15px;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.widget--profile-video {
  position: absolute;
  top: 15px;
  left: 30px;
  text-align: center;
  display: flex;
}

.live-box {
  background-color: #3b5998;
  color: #ffffff;
  padding: 5px;
  font-size: 12px;
  border-radius: 15%;
}

.no-livestream {
  background-color: #3b5998;
}

.no-livestream-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.offline-text {
  color: #ffffff;
  font-size: 16x;
}


/* The device with borders */
.smartphone {
  position: relative;
  width: 360px;
  height: 600px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
/* .smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
} */
.smartphone:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 3px solid red;
  background: black;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}
</style>

